/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { List, ListItem, ListProps, ListItemProps, Theme } from '@audi/audi-ui-react';
import { renderTextWithFootnotesReferencesV2 } from '@oneaudi/feature-app-utils';
import parse from 'html-react-parser';
import React, { Children, FC, ReactNode, cloneElement } from 'react';
import styled from 'styled-components';
import xss from 'xss';

const transform = (node: ReactNode) => {
  if (typeof node === 'string') {
    return renderTextWithFootnotesReferencesV2(node);
  }
  return node;
};

export function renderReactNodeWithFootnotes(nodes: ReactNode[]) {
  return Children.map(nodes, transform);
}

const StyledList = styled(List as FC<ListProps>)`
  ${({ theme }: { theme: Theme }) =>
    `
      margin-block-end: var(${theme.responsive?.spacing.m});
    `}
`;

const StyledListItem = styled(ListItem as FC<ListItemProps>)`
  ${({ theme }: { theme: Theme }) =>
    `
      font-size: var(${theme.responsive?.typography.copy1.fontSize});
      margin-block-end: var(${theme.responsive?.spacing.s});
      margin-block-start: 0px;
    `}
`;

const createElement = (type: string, props?: object, ...children: ReactNode[]): JSX.Element => {
  const renderChildren = () => renderReactNodeWithFootnotes(children);
  if (type === 'ul') {
    return React.createElement(StyledList, props, renderChildren());
  }
  if (type === 'li') {
    return React.createElement(StyledListItem, props, renderChildren());
  }
  return React.createElement(type, props, renderChildren());
};

export const sanitizeRichtext = (htmlString: string) =>
  xss(htmlString, {
    whiteList: {
      b: [],
      i: [],
      u: [],
      ul: [],
      li: [],
      p: [],
      a: ['href', 'target'],
      strong: [],
    },
    stripIgnoreTagBody: ['script'],
    onIgnoreTag: (tag) => {
      if (tag.length > 0) {
        return '';
      }
      return undefined;
    },
  });

export function convertRichText(htmlString: string) {
  return parse(sanitizeRichtext(htmlString), {
    library: {
      cloneElement,
      createElement,
      isValidElement: (element: unknown): boolean => {
        return Boolean(element);
      },
    },
  });
}
