import { SpacingShorthandList, Theme, injectSpacing } from '@audi/audi-ui-react';
import styled, { keyframes } from 'styled-components';
import { isDarkTheme } from '../utils/utils';

export const EditorialColumnsContainer = styled.div<{
  innerSpacing: SpacingShorthandList;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(
    ${({ theme }: { theme: Theme }) =>
      isDarkTheme(theme) ? theme.colors.base.brand.black : theme.colors.base.brand.white}
  );
  ${injectSpacing('padding', 'innerSpacing')}
  padding-inline: var(
    ${({ theme }: { theme: Theme }) => theme.responsive.spacing.pageMargin}
  );

  p {
    word-break: break-word;
    hyphens: auto;
  }

  @media screen and (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.l}px) {
    padding-inline: calc(
      2 * var(${({ theme }: { theme: Theme }) => theme.responsive.spacing.pageMargin})
    );
  }
`;

export const NumbersHeadlineContainer = styled.div`
  text-align: center;
  @media screen and (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.m}px) {
    ${({ theme }: { theme: Theme }) =>
      `
    margin-block-end:  var(${theme.responsive?.spacing.xxl});
    `}
  }
`;

export const animateContainer = keyframes`
  from {
    opacity: 0;
    transform: translateY(40px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
`;

export const LegalInfoContainer = styled.div`
  text-align: center;
`;

export const TextHeadlineContainer = styled.div`
  text-align: center;
  @media screen and (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.m}px) {
    ${({ theme }: { theme: Theme }) =>
      `
    margin-block-end:  var(${theme.responsive?.spacing.xxxl});
    `}
  }
`;
